import JwtService from '@/core/services/jwt.service';

export default function verifyRoute({ from, next, store }) {
  let path = window.location.pathname;
  if (
    !path.startsWith('/giris') &&
    !path.startsWith('/login') &&
    !path.startsWith('/404') &&
    !path.startsWith('/register') &&
    !path.startsWith('/activation') &&
    !path.startsWith('/reset-password') &&
    !path.startsWith('/forgot-password')
  ) {
    JwtService.setCurrentRoute(window.location.href);
  }
  return next();
}
