import jwt from 'jwt-decode';

const ID_TOKEN_KEY = 'dvebcorekn';
const CURRENT_ROUTE_KEY = 'dvebcorerk';
const SELECTED_COMPANY_ID = 'dvebcoreci';
const CUSTOM_PAGINATION_KEY = 'dvebcorepage';
const CUSTOM_PAGINATION_CACHETIME = 900; // 15 dakika = 15 * 60 saniye

export const getToken = () => {
  let _t = window.localStorage.getItem(ID_TOKEN_KEY);
  if (_t) return `Bearer ${_t}`;

  return;
};

export const getCurrentUser = () => {
  let _t = window.localStorage.getItem(ID_TOKEN_KEY);
  if (_t) return jwt(_t);
  return;
};
export const saveToken = (payload) => {
  window.localStorage.setItem(ID_TOKEN_KEY, payload);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getCurrentRoute = () => {
  return window.localStorage.getItem(CURRENT_ROUTE_KEY);
};
export const setCurrentRoute = (payload) => {
  window.localStorage.setItem(CURRENT_ROUTE_KEY, payload);
};
export const destroyCurrentRoute = () => {
  window.localStorage.removeItem(CURRENT_ROUTE_KEY);
};
export const getSelectedCompanyId = () => {
  return window.localStorage.getItem(SELECTED_COMPANY_ID) ?? '';
};
export const setSelectedCompanyId = (payload) => {
  window.localStorage.setItem(SELECTED_COMPANY_ID, payload);
};
export const destroySelectedCompanyId = () => {
  window.localStorage.removeItem(SELECTED_COMPANY_ID);
};
export const getPaginationInfo = (name) => {
  const itemStr = window.localStorage.getItem(CUSTOM_PAGINATION_KEY + name);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  let now = Math.round(Date.now() / 1000);

  if (item.expiry - now < CUSTOM_PAGINATION_CACHETIME) {
    return item.page;
  }
  window.localStorage.removeItem(CUSTOM_PAGINATION_KEY + name);
  return null;
};
export const setPaginationInfo = (name, page) => {
  let now = Math.round(Date.now() / 1000);
  const payload = {
    page: page,
    expiry: now + CUSTOM_PAGINATION_CACHETIME,
  };
  window.localStorage.setItem(
    CUSTOM_PAGINATION_KEY + name,
    JSON.stringify(payload)
  );
};
export const destroyPaginationInfo = (name) => {
  window.localStorage.removeItem(CUSTOM_PAGINATION_KEY + name);
};

export default {
  getToken,
  getCurrentUser,
  saveToken,
  destroyToken,
  getCurrentRoute,
  setCurrentRoute,
  destroyCurrentRoute,
  getSelectedCompanyId,
  setSelectedCompanyId,
  destroySelectedCompanyId,
  getPaginationInfo,
  setPaginationInfo,
  destroyPaginationInfo,
};
