export default function generateToast(app, variant, text) {
  app.$bvToast.toast(text, {
    title: `${variant == 'success' ? 'Success' : 'Error'
      }`,
    variant: variant,
    solid: true,
    toaster: 'b-toaster-top-full',
    //autoHideDelay: 5000000,
    //https://bootstrap-vue.org/docs/components/toast
  })
}
