"use strict";

var DPLayoutContent = function () {
    // Private properties
    var _element;

    // Private functions
    var _getHeight = function () {
        var height;

        height = DPUtil.getViewPort().height;

        if (_element) {
            height = height - parseInt(DPUtil.css(_element, 'paddingTop')) - parseInt(DPUtil.css(_element, 'paddingBottom'));
        }

        height = height - DPLayoutHeader.getHeight();
        height = height - DPLayoutSubheader.getHeight();
        height = height - DPLayoutFooter.getHeight();

        return height;
    }

    // Public methods
    return {
        init: function (id) {
            _element = DPUtil.getById(id);
        },

        getHeight: function () {
            return _getHeight();
        },

        getElement: function () {
            return _element;
        }
    };
}();



export default DPLayoutContent;