"use strict";

var DPLayoutAside = function () {
	// Private properties
	var _body;
	var _element;
	var _offcanvasObject;

	// Private functions
	// Initialize
	var _init = function () {
		var offcanvasClass = DPUtil.hasClass(_element, 'aside-offcanvas-default') ? 'aside-offcanvas-default' : 'aside';

		// Initialize mobile aside offcanvas
		_offcanvasObject = new DPOffcanvas(_element, {
			baseClass: offcanvasClass,
			overlay: true,
			closeBy: 'dp_aside_close_btn',
			toggleBy: {
				target: 'dp_aside_mobile_toggle',
				state: 'mobile-toggle-active'
			}
		});

		// Handle Minimized Aside Hover
		if (DPUtil.hasClass(_body, 'aside-fixed') && DPUtil.hasClass(_body, 'aside-minimize-hoverable')) {
			var insideTm;
			var outsideTm;

			// Handle Aside Hover Mode
			DPUtil.addEvent(_element, 'mouseenter', function (e) {
				e.preventDefault();

				if (DPUtil.isBreakpointUp('lg') === false) {
					return;
				}

				if (outsideTm) {
					clearTimeout(outsideTm);
					outsideTm = null;
				}

				insideTm = setTimeout(function () {
					if (DPUtil.hasClass(_body, 'aside-minimize') && DPUtil.isBreakpointUp('lg')) {
						DPUtil.removeClass(_body, 'aside-minimize');

						// Hover class
						DPUtil.addClass(_body, 'aside-minimize-hover');

						DPLayoutAsideMenu.getMenu().scrollUpdate();
						DPLayoutAsideMenu.getMenu().scrollTop();
					}
				}, 50);
			});

			DPUtil.addEvent(_element, 'mouseleave', function (e) {
				e.preventDefault();

				if (DPUtil.isBreakpointUp('lg') === false) {
					return;
				}

				if (insideTm) {
					clearTimeout(insideTm);
					insideTm = null;
				}

				outsideTm = setTimeout(function () {
					if (DPUtil.hasClass(_body, 'aside-minimize-hover') && DPUtil.isBreakpointUp('lg')) {
						DPUtil.removeClass(_body, 'aside-minimize-hover');
						DPUtil.addClass(_body, 'aside-minimize');

						// Hover class
						DPLayoutAsideMenu.getMenu().scrollUpdate();
						DPLayoutAsideMenu.getMenu().scrollTop();
					}
				}, 100);
			});
		}
	}

	// Public methods
	return {
		init: function (id) {
			_element = DPUtil.getById(id);
			_body = DPUtil.getBody();

			if (!_element) {
				return;
			}

			// Initialize
			_init();
		},

		getElement: function () {
			return _element;
		},

		getOffcanvas: function () {
			return _offcanvasObject;
		},

		isFixed: function () {
			return DPUtil.hasClass(_body, 'aside-fixed');
		},

		isMinimized: function () {
			return (DPUtil.hasClass(_body, 'aside-fixed') && DPUtil.hasClass(_body, 'aside-minimize'));
		},

		isHoverable: function () {
			return (DPUtil.hasClass(_body, 'aside-fixed') && DPUtil.hasClass(_body, 'aside-minimize-hoverable'));
		}
	};
}();



export default DPLayoutAside;