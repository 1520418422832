import Vue from 'vue';

// Buttons
Vue.component('ActionButton', () =>
  import('@/components/global/buttons/ActionButton')
);
Vue.component('DeleteButton', () =>
  import('@/components/global/buttons/DeleteButton')
);
Vue.component('GreenButton', () =>
  import('@/components/global/buttons/GreenButton')
);
Vue.component('InfoButton', () =>
  import('@/components/global/buttons/InfoButton')
);
Vue.component('SwitchButton', () =>
  import('@/components/global/buttons/SwitchButton')
);
Vue.component('WhiteButton', () =>
  import('@/components/global/buttons/WhiteButton')
);

// Form Elements
Vue.component('Checkbox', () =>
  import('@/components/global/formElements/Checkbox')
);
Vue.component('CopyButtonLabel', () =>
  import('@/components/global/formElements/CopyButtonLabel')
);
Vue.component('DateTime', () =>
  import('@/components/global/formElements/DateTime')
);
Vue.component('Input', () => import('@/components/global/formElements/Input'));
Vue.component('NumberInput', () =>
  import('@/components/global/formElements/NumberInput')
);
Vue.component('PasswordLabel', () =>
  import('@/components/global/formElements/PasswordLabel')
);
Vue.component('PasswordStrengthViewer', () =>
  import('@/components/global/formElements/PasswordStrengthViewer')
);
Vue.component('Select', () =>
  import('@/components/global/formElements/Select')
);
Vue.component('MultiSelect', () =>
  import('@/components/global/formElements/MultiSelect')
);
// Vue.component('multiselect', () => import('vue-multiselect'));
Vue.component('Textarea', () =>
  import('@/components/global/formElements/Textarea')
);

// Modals
Vue.component('DeleteOperation', () =>
  import('@/components/global/modals/DeleteOperation')
);

// Others
Vue.component('Card', () => import('@/components/global/Card'));
Vue.component('InsertUpdateModal', () =>
  import('@/components/global/InsertUpdateModal')
);
Vue.component('DetailModal', () => import('@/components/global/DetailModal'));
Vue.component('ViewModal', () => import('@/components/global/ViewModal'));
Vue.component('ImportModal', () => import('@/components/global/ImportModal'));
Vue.component('ConfirmModal', () => import('@/components/global/ConfirmModal'));
Vue.component('Filters', () => import('@/components/global/Filters'));
Vue.component('InfoBox', () => import('@/components/global/InfoBox'));
Vue.component('Loader', () => import('@/view/content/Loader'));
Vue.component('Label', () => import('@/components/global/Label'));
Vue.component('List', () => import('@/components/global/List'));
Vue.component('CustomPagination', () =>
  import('@/components/global/CustomPagination')
);
