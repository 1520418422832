import {
  LOGOUT,
  GET_MY_USER,
  VERIFY_AUTH,
} from '@/core/services/store/auth.module';
import JwtService from '@/core/services/jwt.service';

export default function verifyUser({ next, store, router }) {
  const currentUser = JwtService.getCurrentUser();

  if (currentUser) {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    store
      .dispatch(GET_MY_USER)
      .then((res) => {
        if (res.success == true && res.error == false) {
          //Control url
        } else {
          store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
        }
      })
      .catch((err) => {
        store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
      });
  } else {
    store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
  }

  return next();
}
