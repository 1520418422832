"use strict";

var DPLayoutStretchedCard = function () {
	// Private properties
	var _element;

	// Private functions
	var _init = function () {
		var scroll = DPUtil.find(_element, '.card-scroll');
		var cardBody = DPUtil.find(_element, '.card-body');
		var cardHeader = DPUtil.find(_element, '.card-header');

		var height = DPLayoutContent.getHeight();

		height = height - parseInt(DPUtil.actualHeight(cardHeader));

		height = height - parseInt(DPUtil.css(_element, 'marginTop')) - parseInt(DPUtil.css(_element, 'marginBottom'));
		height = height - parseInt(DPUtil.css(_element, 'paddingTop')) - parseInt(DPUtil.css(_element, 'paddingBottom'));

		height = height - parseInt(DPUtil.css(cardBody, 'paddingTop')) - parseInt(DPUtil.css(cardBody, 'paddingBottom'));
		height = height - parseInt(DPUtil.css(cardBody, 'marginTop')) - parseInt(DPUtil.css(cardBody, 'marginBottom'));

		height = height - 3;

		DPUtil.css(scroll, 'height', height + 'px');
	}

	// Public methods
	return {
		init: function (id) {
			_element = DPUtil.getById(id);

			if (!_element) {
				return;
			}

			// Initialize
			_init();

			// Re-calculate on window resize
			DPUtil.addResizeHandler(function () {
				_init();
			});
		},

		update: function () {
			_init();
		}
	};
}();



export default DPLayoutStretchedCard;