import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";

//for NavigationDuplicated error.
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

// Import middleware
import verifyRoute from "@/middleware/verifyRoute";
import verifyUser from "@/middleware/verifyUser";

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "anasayfa",
          meta: {
            title: "Ana Sayfa | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/teklif",
          name: "teklif",
          meta: {
            title: "Teklifler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "mulkler",
              meta: {
                title: "Mülkler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Properties.vue"),
            },
            {
              path: "mulk-detay/:id/",
              name: "mulk-detay",
              meta: {
                title: "Mülk Detayı | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/PropertyDetail.vue"),
            },
            {
              path: "mulk-ekle",
              name: "mulk-ekle",
              meta: {
                title: "Mülk Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddProperty.vue"),
            },
          ]
        },
        {
          path: "/bayiler",
          name: "bayiler",
          meta: {
            title: "Bayiler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/Bayiler.vue"),
        },
        {
          path: "/teknik-destek",
          name: "teknik-destek",
          meta: {
            title: "Teknik Destek | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children: [
            {
              path: "/",
              name: "teknik-destek-listesi",
              meta: {
                title: "Teknik Destek | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Support.vue"),
            },
            {
              path: "teknik-destek-detay",
              name: "teknik-destek-detayı",
              meta: {
                title: "Teknik Destek | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/SupportDetail.vue"),
            },
          ],
        },

        {
          path: "/is-ortaklari",
          name: "is-ortaklari-list",
          meta: {
            title: "İş Ortakları | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "is-ortaklari-list",
              meta: {
                title: "İş Ortakları | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Isortaklari.vue"),
            },
            {
              path: "is-ortaklari-ekle",
              name: "is-ortaklari-ekle",
              meta: {
                title: "İş Ortağı Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Addisortaklari.vue"),
            },
            {
              path: "is-ortaklari-calisanlar",
              name: "is-ortaklari-calisanlar",
              meta: {
                title: "İş Ortağı Çalışanlar | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Calisanisortaklari.vue"),
            },
          ]
        },
        
        
        
        {
          path: "/musteriler",
          name: "musteriler",
          meta: {
            title: "Müşteriler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "musteriler",
              meta: {
                title: "Müşteriler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Customers.vue"),
            },
            {
              path: "musteri-ekle",
              name: "musteri-ekle",
              meta: {
                title: "Müşteri Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddCustomer.vue"),
            },
          ]
        },
        
        {
          path: "/talep-ekle",
          name: "talep-ekle",
          meta: {
            title: "Talep Ekle | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/AddRequest.vue"),
        },
        {
          path: "/hatirlatmalar",
          name: "hatirlatmalar",
          meta: {
            title: "Talepler | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "hatirlatmalar",
              meta: {
                title: "Talepler | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Reminders.vue"),
            },
            {
              path: "hatirlatma-ekle",
              name: "hatirlatma-ekle",
              meta: {
                title: "Talep Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/AddReminder.vue"),
            },
          ]
        },
        {
          path: "/kiracilar",
          name: "kiracilar",
          meta: {
            title: "Kiracılar | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "/",
              name: "kiracilar",
              meta: {
                title: "Kiracılar | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/Kiracilar.vue"),
            },
            {
              path: "ekle",
              name: "kiraci-ekle",
              meta: {
                title: "Kiracı Ekle | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/KiraciEkle.vue"),
            },
            {
              path: "detay/:id/",
              name: "kiraci-detay",
              meta: {
                title: "Kiracı Detay | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/KiraciDetay.vue"),
            },
          ]
        },
        {
          path: "/yonetim",
          name: "yonetim",
          meta: {
            title: "Yönetim | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/layout/SubLayout"),
          children:[
            {
              path: "musteriler",
              name: "yonetim/musteriler",
              meta: {
                title: "Müşteri Yönetimi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/manage/Customers.vue"),
            },
            {
              path: "kiracilar",
              name: "yonetim/kiracilar",
              meta: {
                title: "Kiracı Yönetimi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/manage/Kiraci.vue"),
            },
            {
              path: "mulkler",
              name: "yonetim/mulkler",
              meta: {
                title: "Müşteri Yönetimi| DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/manage/Properties.vue"),
            },
          ]
        },
        {
          path: "/bize-ulasin",
          name: "bize-ulasin",
          meta: {
            title: "Bize Ulaşın | DveB Panel",
            middleware: [verifyUser],
          },
          component: () => import("@/view/pages/ContactUs.vue"),
        },
        {
          path: "/sistem-kullanicilari",
          component: () => import("@/view/pages/systemUsers/Home.vue"),
          children: [
            {
              path: "",
              name: "systemUsers",
              meta: {
                title: "Sistem Kullanıcı Listesi | DveB Panel",
                middleware: [verifyUser],
              },
              component: () => import("@/view/pages/systemUsers/List.vue"),
            },
          ],
        },

      ],
    },
    {
      path: "/",
      component: () => import("@/view/layout/Auth"),
      children: [
        {
          path: "/giris",
          name: "login",
          meta: {
            title: "Giriş | DveB Panel",
            middleware: [verifyRoute],
          },
          component: () => import("@/view/pages/auth/Login"),
        },
         
      ],
    },
    {
      path: "/kayitol",
      name: "kayitol",
      meta: {
        title: "Kayıt Ol | DveB Panel",
      },
      component: () => import("@/view/pages/auth/SignUp"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      meta: {
        title: "Sayfa bulunamadı! | DveB Panel",
      },
      component: () => import("@/view/pages/error/Error.vue"),
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      store,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});
export default router;
